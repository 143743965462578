'use client';

import { useTagPlan } from 'features/tagPlan/business/useTagPlan';
import cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { Text } from '@components/Text/Text.component';
import { Switch } from '@form/components/Switch/Switch.component';
import styles from './ATnoConsentSwitcher.module.scss';

const ATnoConsentSwitcherComponent = () => {
  const [hasCookieBeenConsented, setHasCookieBeenConsented] = useState<string | undefined>(undefined);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    setHasCookieBeenConsented(cookies.get('TC_PRIVACY'));
  }, [refresh]);

  const tagPlan = useTagPlan();
  const disabled = hasCookieBeenConsented === undefined;

  const handleToggle = () => {
    tagPlan.ATsetConsent();
    setRefresh((prev) => !prev);
  };

  return (
    <div className={styles.containerSwitcher}>
      <Text alignment="center" i18nKey="common.transversal.privacyPolicy.ATnoConsentLabel" tag="span" variant="small" />
      <Switch checked={tagPlan.ATisConsent()} disabled={disabled} onChange={handleToggle} />
    </div>
  );
};

export default ATnoConsentSwitcherComponent;
