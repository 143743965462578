/* eslint-disable react/no-danger */
import { PlcPageHeader } from '../PlcPageHeader/PlcPageHeader.component';
import { PlcPageLayout } from '../PlcPageLayout/PlcPageLayout.component';
import { TransversalPageProps } from 'pages/[transversal]';
import React, { ComponentPropsWithRef, FC } from 'react';
import { BreadCrumbStep } from '@components/BreadCrumb/BreadCrumb.types';
import { ContentBody } from '@components/content/ContentBody/ContentBody.component';
import ATnoConsentSwitcherComponent from '@layout/components/ATnoConsentSwitcher/ATnoConsentSwitcher.component';
import { urlEnum } from '@utils/url';
import classnames from 'classnames';
import { ATLevel2, PlcTagProps } from 'features/tagPlan/business/TagPlan.types';

export type TransversalLayoutProps = ComponentPropsWithRef<'main'> & TransversalPageProps;

const TransversalLayout: FC<TransversalLayoutProps> = ({ pageCode, pageContent, resolvedUrl, className, ...rest }) => {
  const rootClass = classnames('plc-page', pageCode, className);
  const pageTitle = `common.transversal.${pageCode}.title`;
  const steps: BreadCrumbStep[] = [
    {
      label: 'common.newspaper-short',
      linkProps: { as: urlEnum.home, href: urlEnum.home },
    },
    {
      label: pageTitle,
    },
  ];

  const tagProps: PlcTagProps = React.useMemo(
    () => ({
      level2: ATLevel2.Footer,
      tagPageNameI18nArray: [pageTitle],
    }),
    []
  );

  return (
    <PlcPageLayout className={rootClass} metaTitleI18n={pageTitle} tagProps={tagProps} {...rest}>
      <PlcPageHeader breadcrumbSteps={steps} heroImgSrc="inscription" i18nTitle={pageTitle} />
      <section className="wrapper-s main-section">
        {resolvedUrl === urlEnum.privacyPolicy && <ATnoConsentSwitcherComponent/>}
        <ContentBody body={pageContent} isPartial={false} />
      </section>
    </PlcPageLayout>
  );
};

export { TransversalLayout };
