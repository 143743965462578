import Error from './_error';
import { PageCode, PagesApi as PagesApiClient } from 'pleinchamp-api-client';
import * as React from 'react';
import { ApiConfiguration, axiosInstance } from '@api/business/api.conf';
import { TransversalLayout } from '@layout/components/TransversalLayout/TransversalLayout.component';
import { ApiStatusCode } from '@utils/api.utils';
import { defaultNamespaces } from '@utils/strings';
import { return404, urlEnum } from '@utils/url';
import { PlcNextPage, PlcNextPageContext } from 'features/business/app.types';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

const PagesApi = new PagesApiClient(ApiConfiguration, undefined, axiosInstance);

const allowedUrls = [
  urlEnum.faq,
  urlEnum.tos,
  urlEnum.privacyPolicy,
  urlEnum.legalNotice,
  urlEnum.sitemap,
  urlEnum.advertisement,
  urlEnum.forecastTransparency,
  urlEnum.espaceAssurance,
];

export type TransversalPageProps = { pageCode: string; pageContent: string; resolvedUrl: string | undefined };

const TransversalPage: PlcNextPage<Partial<TransversalPageProps>> = ({ pageCode, pageContent, resolvedUrl }) =>
  pageCode && pageContent ? (
    <TransversalLayout pageCode={pageCode} pageContent={pageContent} resolvedUrl={resolvedUrl} />
  ) : (
    <Error errorCode={ApiStatusCode.notFound} />
  );

export async function getServerSideProps(ctx: PlcNextPageContext) {
  const resolvedUrl = ctx.resolvedUrl as urlEnum;

  if (!allowedUrls.includes(resolvedUrl)) {
    const notFoundProps = return404(ctx);

    return {
      props: {
        errorCode: notFoundProps.errorCode,
        ...(await serverSideTranslations(ctx.locale || 'fr', notFoundProps.namespacesRequired)),
      },
    };
  }

  const pageCodeIndex = Object.values(urlEnum).findIndex((url) => url === resolvedUrl);
  const pageCode = pageCodeIndex > -1 ? (Object.keys(urlEnum)[pageCodeIndex] as PageCode) : undefined;

  let pageContent: string | undefined;
  if (pageCode) {
    try {
      const resp = await PagesApi.getPage(pageCode);
      pageContent = resp.data;
    } catch (e) {
      console.error('Error while calling ', pageCode, e);
    }
  }
  return {
    props: {
      ...(await serverSideTranslations(ctx.locale || 'fr', defaultNamespaces)),
      pageCode,
      pageContent,
      resolvedUrl,
    },
  };
}

export default TransversalPage;
